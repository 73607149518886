<template>
  <DashboardCard
    :loading="loading"
    :title="$t('terms.supplierTerms.purchaseHistoryTop20Pie.title')"
    :action-type="fullView ? '' : CARD_ACTIONS.REDIRECT"
    @on-redirect="routeToTopPurchasedProducts"
  >
    <div class="d-flex justify-content-between align-items-center">
      <div class="pie-chart-wrapper">
        <PieChart
          :total-amount="totalPurchaseAmount"
          :primary-amount="top20PurchaseAmount"
          :secondary-amount="restOfProductsPurchaseAmount"
          :height="120"
          :width="120"
          :cutout-percentage="70"
        />
      </div>
      <div class="content">
        <div class="details">
          <p>
            <small class="text-muted subtitle">
              <span class="dot primary" />

              {{ `${$t('terms.supplierTerms.purchaseHistoryTop20Pie.top20MostPurchased')}` }}
            </small>
          </p>

          <p>
            <span class="dot secondary" />
            <small class="text-muted subtitle">
              {{ $t('terms.supplierTerms.purchaseHistoryTop20Pie.restOfPurchasedProducts') }}
            </small>
          </p>
        </div>
      </div>
    </div>
  </DashboardCard>
</template>

<script>
import { DateTime } from 'luxon';
import { computed } from 'vue';

import { useTenancy } from '@/modules/auth';

import { DashboardCard, CARD_ACTIONS } from '@/modules/core';

import { useTenantTopPurchases } from '../../compositions/order/useOrderItemsAggregationByProduct';
import { getISODateFromDateTime } from '../../purchaseManagementFormatters';
import PieChart from './components/PieChart';

const sumPurchaseAmount = (aggregations) =>
  aggregations.reduce((sum, currentAggregation) => sum + currentAggregation.sumPrice, 0);

export default {
  components: { DashboardCard, PieChart },
  props: {
    fullView: { type: Boolean, default: false },
  },
  setup() {
    const { currentTenant } = useTenancy();

    const { tenantTopPurchases, loading } = useTenantTopPurchases(
      computed(() => ({
        tenantId: currentTenant.value.id,
        toDate: getISODateFromDateTime(DateTime.utc().endOf('month')),
        fromDate: getISODateFromDateTime(DateTime.utc().endOf('month').startOf('month').minus({ months: 5 })),
      }))
    );

    const top20PurchaseAmount = computed(() => sumPurchaseAmount(tenantTopPurchases.value.slice(0, 20)));
    const totalPurchaseAmount = computed(() => sumPurchaseAmount(tenantTopPurchases.value));
    const restOfProductsPurchaseAmount = computed(() => sumPurchaseAmount(tenantTopPurchases.value.slice(20)));

    const top20ItemsSuppliersNum = computed(
      () => [...new Set(tenantTopPurchases.value.slice(0, 20).map((aggregation) => aggregation.supplierId))].length
    );

    return {
      loading,
      totalPurchaseAmount,
      restOfProductsPurchaseAmount,
      top20PurchaseAmount,
      top20ItemsSuppliersNum,
      CARD_ACTIONS,
    };
  },
  methods: {
    routeToTopPurchasedProducts() {
      this.$router.push({ name: 'topPurchasedProducts' });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/colors.module.scss';
@import '@/stylesheets/scss/typography.module.scss';

.card-header {
  .el-button {
    background-color: inherit;
    border: 0;
    font-weight: $font-weight-normal;

    &:hover {
      background-color: inherit;
    }
    &:focus {
      outline: 0;
    }
  }
}

.pie-chart-wrapper {
  align-items: center;
}

.content {
  margin-top: 70px;
  .details {
    .dot {
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      margin-left: 0.5rem;
      &.primary {
        background: $primary;
      }
      &.secondary {
        background: #d7dff8;
      }
    }
  }
}
</style>
