<template>
  <div>
    <DashboardCard
      :title="$t('terms.supplierTerms.purchaseHistoryChart.purchases')"
      :action-type="CARD_ACTIONS.MONTH_STEP_RANGE"
      @on-range-updated="updateHalfYearListener"
    >
      <div class="chartWrapper">
        <FloatingRoundedBarChart
          :chart-data="chartData"
          :height="124"
          :min="fromDate.toJSDate()"
          :max="toDate.toJSDate()"
        />
      </div>
    </DashboardCard>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { computed, ref, watch } from 'vue';

import { useTenancy } from '@/modules/auth';
import { useOrderAggregationByMonth } from '@/modules/purchase-management/compositions/order/useOrderAggregationByMonth';
import FloatingRoundedBarChart from '@/modules/purchase-management/components/supplierPurchases/components/FloatingRoundedBarChart';
import colors from '@/stylesheets/scss/colors.module.scss';
import { DashboardCard, CARD_ACTIONS } from '@/modules/core';

const getClosestHalfYearMonth = () => {
  const currentMonth = DateTime.local().month;
  return Math.ceil(currentMonth / 6) * 6;
};

export default {
  components: { FloatingRoundedBarChart, DashboardCard },
  props: {
    supplierId: { type: String, default: null },
  },
  setup(props) {
    const { currentTenant } = useTenancy();

    const triggerQuery = ref(true);

    const toDate = ref(DateTime.local().set({ month: getClosestHalfYearMonth() }).startOf('month'));
    const fromDate = computed(() => toDate.value.minus({ months: 5 }));

    const fromDateFormatted = computed(() => fromDate.value.toFormat('yyyy-LL'));

    watch(fromDateFormatted, () => (triggerQuery.value = true));

    const { groupByMonth } = useOrderAggregationByMonth(
      computed(() => currentTenant.value.id),
      computed(() => props.supplierId),
      fromDateFormatted,
      computed(() => toDate.value.toFormat('yyyy-LL')),
      triggerQuery
    );

    const groupByMonthAggregation = computed(() => {
      return groupByMonth.value.reduce(
        (acc, currentAggregation) => ({
          ...acc,
          [currentAggregation.isoMonth]: currentAggregation,
        }),
        {}
      );
    });

    const xAxisDates = computed(() => {
      let startDate = fromDate.value;

      const dates = [];
      while (startDate <= toDate.value) {
        dates.push(startDate);
        startDate = startDate.plus({ months: 1 });
      }

      return dates;
    });

    const chartData = computed(() => ({
      datasets: [
        {
          data: groupByMonthAggregation.value
            ? xAxisDates.value?.map((date) => ({
                x: date.toJSDate(),
                y: groupByMonthAggregation.value[date.toFormat('yyyy-LL')]?.amount ?? 0,
              }))
            : [],
          hoverBackgroundColor: '#5E81E4',
          backgroundColor: colors.primary,
          barThickness: 10,
        },
      ],
    }));
    const updateHalfYearListener = (data) => {
      const newToDateValue = data.toDate.value;
      toDate.value = newToDateValue;
    };
    return {
      fromDate,
      toDate,
      chartData,
      CARD_ACTIONS,
      updateHalfYearListener,
    };
  },
};
</script>

<style scoped type="scss">
.chartWrapper {
  width: 100%;
}
</style>
