import { mixins, Line } from 'vue-chartjs';
import { currency } from '@/locale/numberConfig';
import { typographySecondary } from '@/stylesheets/scss/typography.module.scss';
import { fontFamilyBase } from '@/stylesheets/scss/fonts.module.scss';

const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    min: { type: Date },
    max: { type: Date },
  },
  computed: {
    options() {
      return {
        devicePixelRatio: 2,
        maintainAspectRatio: false,
        responsive: true,
        hover: {
          mode: 'x',
          intersect: false,
        },
        legend: {
          display: false,
        },
        tooltips: {
          display: true,
          displayColors: false,
          bodyAlign: this.$direction === 'rtl' ? 'right' : 'left',
          rtl: this.$direction === 'rtl',
          callbacks: {
            title: () => {},
            label: (tooltipItem, data) => {
              const price = this.$i18n.t('terms.supplierTerms.purchaseHistoryTable.averagePrice');
              const quantityString = this.$i18n.t('commons.quantity');
              const quantity = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].metadata.quantity;
              return [
                `${price}: ${Number(tooltipItem.yLabel).toLocaleString(this.$i18n.locale, currency)}`,
                `${quantityString}: ${Number(quantity).toLocaleString()}`,
              ];
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: typographySecondary,
                fontFamily: fontFamilyBase,
                beginAtZero: true,
                maxTicksLimit: 4,
                callback: (value) => {
                  return Number(value).toLocaleString(this.$i18n.locale, {
                    ...currency,
                    minimumFractionDigits: 0,
                  });
                },
              },
              gridLines: {
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'month',
                displayFormats: {
                  month: 'MM.YY',
                },
              },
              offset: true,
              ticks: {
                min: this.min,
                max: this.max,
                fontColor: typographySecondary,
                fontFamily: fontFamilyBase,
                maxRotation: 0,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      };
    },
  },
  watch: {
    options(options) {
      this.renderChart(this.chartData, options);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
