<template>
  <PageLayout v-loading="!isDone">
    <template #header>
      <div class="d-flex flex-column gap-2">
        <p class="supplier-name">{{ supplierName }}</p>
        <p class="page-title">{{ $t('terms.supplierSettings.title') }}</p>
      </div>
    </template>
    <Tabs :tabs="tabs" :active-tab.sync="tab" @click="handleTabClick" />
    <div v-if="tab === TABS.PRODUCTS">
      <SupplierProductsFromCatalogTable
        :products="products"
        :purchase-list-items="purchaseListItems"
        :loading="!isDone"
        :supplier-id="supplierId"
        @on-purchase-list-item-update="onPurchaseListStatusUpdate"
      />
    </div>
    <div v-if="tab === TABS.GENERAL_TERMS">
      <GeneralTerms :supplier-id="supplierId" :business-id="currentTenant.id" />
      <PaymentTerms v-if="hasPermissionForPaymentTerms" :supplier="{ id: supplierId, name: supplierName }" />
      <OrderTerms v-if="hasPermissionForViewingOrderTerms" :supplier-id="supplierId" :business-id="currentTenant.id" />
    </div>
  </PageLayout>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { Tabs, PageLayout } from '@/modules/core';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';
import { useTenancy, useUserPermissions } from '@/modules/auth';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';
import { usePurchaseListItems } from '@/modules/orderProcess/compositions/usePurchaseListItems';
import { useUpdatePurchaseListItem } from '@/modules/purchase-management/compositions/purchaseList/useUpdatePurchaseListItem';
import { useBusinessByNames } from '@/modules/business/compositions/business';

import GeneralTerms from '../../components/generalTerms/GeneralTerms.vue';
import PaymentTerms from '../../components/PaymentTerms';
import OrderTerms from '../../components/orderTerms/OrderTerms';

import SupplierProductsFromCatalogTable from './components/SupplierProductsFromCatalogTable.vue';
import { useCatalogs } from '@/modules/catalog/compositions/useCatalog';
import { useProductsNew } from '@/modules/product';

const ALL_RESULTS_LIMIT = 500;
const TABS = {
  PRODUCTS: 0,
  GENERAL_TERMS: 1,
};
export default {
  name: 'SupplierSettingsPage',
  components: { Tabs, GeneralTerms, PaymentTerms, OrderTerms, PageLayout, SupplierProductsFromCatalogTable },
  props: {},
  emits: [''],
  setup() {
    const root = getCurrentInstance().proxy;
    const tab = ref(TABS.PRODUCTS);

    const tabs = [
      { text: root.$t('terms.supplierSettings.tabs.products') },
      { text: root.$t('terms.supplierSettings.tabs.generalTerms') },
    ];
    const supplierId = computed(() => root.$route.params.supplierId);

    const { currentTenant } = useTenancy();
    const {
      allResults: products,
      loading: productsLoading,
      refetch: refetchProducts,
    } = useFetchAll({
      hook: useProductsNew,
      variables: {
        businessId: supplierId.value,
      },
      dataSelector: 'productsNew',
      limit: ALL_RESULTS_LIMIT,
    });
    const {
      allResults: catalogs,
      loading: catalogLoading,
      refetch: refetchCatalogs,
    } = useFetchAll({
      limit: ALL_RESULTS_LIMIT,
      dataSelector: 'catalogs',
      hook: useCatalogs,
      variables: { businessId: supplierId.value },
    });
    const {
      allResults: purchaseListItems,
      isDone: isPurchaseListItemsDone,
      refetch: refetchPurchaseListItems,
    } = useFetchAll({
      hook: usePurchaseListItems,
      variables: {
        currentTenant,
        supplierId: supplierId.value,
      },
      dataSelector: 'purchaseListItems',
      limit: ALL_RESULTS_LIMIT,
    });

    const mappedProducts = computed(() => {
      const data = products.value.map((product) => {
        const relevantCatalogs = catalogs.value.filter(({ productIds }) => productIds.includes(product.id));

        return {
          ...product,
          catalogs: relevantCatalogs,
          availability: !catalogLoading.value ? relevantCatalogs.length > 0 : undefined,
        };
      });

      return data;
    });

    const { result: business } = useBusinessByNames({ businessIds: computed(() => [supplierId.value]) });

    const supplierName = computed(() => business.value[0]?.name);

    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasPermissionForPaymentTerms = isUserPermittedForActiveTenant(
      USER_NEW_PERMISSIONS_LIST.PURCHASE_PAYMENT_TERMS
    );
    const hasPermissionForViewingOrderTerms = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.ORDER_PROCESS);

    const isDone = computed(() => isPurchaseListItemsDone.value && !catalogLoading.value && !productsLoading.value);

    const refetchData = () => {
      refetchProducts();
      refetchCatalogs();
      refetchPurchaseListItems();
    };

    const { updatePurchaseListItem } = useUpdatePurchaseListItem(refetchData);

    const onPurchaseListStatusUpdate = ({ isRecommended, productId }) => {
      updatePurchaseListItem({
        isRecommended,
        productId,
        businessId: currentTenant.value.id,
        supplierId: supplierId.value,
      });
    };

    const handleTabClick = (newTab) => {
      if (newTab === TABS.PRODUCTS) {
        refetchData();
      }
    };

    return {
      TABS,
      tab,
      tabs,
      isDone,
      products: mappedProducts,
      supplierId,
      supplierName,
      currentTenant,
      purchaseListItems,
      hasPermissionForPaymentTerms,
      hasPermissionForViewingOrderTerms,
      handleTabClick,
      onPurchaseListStatusUpdate,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.supplier-name {
  color: $typography-primary;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: -0.02rem;
  font-size: 2rem;
}
.page-title {
  color: $typography-primary;
  font-weight: 500;
  font-size: 0.875rem;
}
</style>
