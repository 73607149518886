import { computed } from 'vue';
import { Pie } from 'vue-chartjs';
import colors from '@/stylesheets/scss/colors.module.scss';
import { useCurrency } from '@/locale/useCurrency';
import { formatCurrencyOrDash, formatPercentOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';

export default {
  extends: Pie,
  props: {
    totalAmount: { type: Number, default: 0 },
    secondaryAmount: { type: Number, required: true },
    primaryAmount: { type: Number, required: true },
    cutoutPercentage: { type: Number, default: 70 },
    padding: { type: Object },
  },
  setup(props) {
    const { currencyFormat: baseCurrencyFormat } = useCurrency();
    const currencyFormat = computed(() => ({ ...baseCurrencyFormat.value, maximumFractionDigits: 0 }));

    return {
      currencyFormat,
      unformattedPrimaryAmountPercent: computed(() => Math.round((props.primaryAmount / props.totalAmount) * 100)),
    };
  },
  computed: {
    primaryAmountPercent() {
      return this.formatPercent(this.totalAmount !== 0 ? this.unformattedPrimaryAmountPercent : 0);
    },
    secondaryAmountPercent() {
      return this.formatPercent(this.totalAmount !== 0 ? 100 - this.unformattedPrimaryAmountPercent : 0);
    },
  },
  watch: {
    primaryAmount(newValue) {
      this.$data._chart.data.datasets[0].data[0] = newValue;
      this.$data._chart.update();
    },
    secondaryAmount(newValue) {
      this.$data._chart.data.datasets[0].data[1] = newValue;
      this.$data._chart.update();
    },
  },
  methods: {
    formatMoney(value) {
      return formatCurrencyOrDash(value, this.currencyFormat);
    },
    formatPercent(number) {
      return formatPercentOrDash(number);
    },
  },
  mounted() {
    this.renderChart(
      {
        datasets: [
          {
            data: [this.primaryAmount, this.secondaryAmount],
            backgroundColor: [colors.primary, '#D7DFF8'],
            hoverBackgroundColor: ['#5E81E4', '#D7DFF8'],
            hoverBorderWidth: [0, 0],
          },
        ],
      },
      {
        maintainAspectRatio: false,
        responsive: true,
        cutoutPercentage: this.cutoutPercentage,
        layout: {
          padding: {
            left: this.$direction === 'rtl' ? 25 : 0,
            right: this.$direction === 'rtl' ? 0 : 25,
            top: 25,
            bottom: 4,
          },
        },
        caretSize: 0,
        tooltips: {
          display: true,
          displayColors: true,
          bodyAlign: this.$direction === 'rtl' ? 'right' : 'left',
          rtl: this.$direction === 'rtl',
          footerFontStyle: 'normal',
          callbacks: {
            title: () => {},
            footer: ([tooltipItem]) => {
              const isPrimary = tooltipItem?.index === 0;

              const percent = isPrimary ? this.primaryAmountPercent : this.secondaryAmountPercent;
              const amount = isPrimary ? this.primaryAmount : this.secondaryAmount;

              return [`${percent} (${this.formatMoney(amount)})`];
            },
            label: (tooltipItem) => {
              const isPrimary = tooltipItem.index === 0;

              const text = isPrimary
                ? this.$i18n.t('terms.supplierTerms.purchaseHistoryTop20Pie.top20MostPurchasedTooltip')
                : this.$i18n.t('terms.supplierTerms.purchaseHistoryTop20Pie.restOfProducts');
              return [`  ${text}`];
            },
          },
        },
      }
    );
  },
};
