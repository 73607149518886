<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('terms.suppliersSettings.title') }}</h1>
    </template>
    <div class="d-flex flex-column gap-2">
      <div class="d-flex flex-column gap-2">
        <p class="suppliers">{{ $t('terms.suppliersSettings.suppliers') }} ({{ totalCount }})</p>
        <div class="d-flex w-100 justify-content-between">
          <div class="search-supplier">
            <el-input
              v-model="supplierSearch"
              size="small"
              prefix-icon="el-icon-search"
              :placeholder="$t('terms.suppliersSettings.supplierTable.search')"
            />
          </div>
          <Button
            v-if="hasPermissionForEditingAgreements"
            :style="{ height: '2rem', paddingTop: '0px', paddingBottom: '0px' }"
            @click="showAddSupplierModal = true"
          >
            <div class="d-flex gap-1 align-items-center">
              <PlusIcon :size="20" />
              {{ $t('terms.suppliersSettings.addSupplier') }}
            </div>
          </Button>
        </div>
      </div>
      <SuppliersTable
        :loading="loading"
        :total="totalCount"
        :agreements="agreements"
        :order-terms="orderTerms"
        :supplier-search="supplierSearch"
        :purchase-list-items="purchaseListItems"
      />
      <AddSupplierModal
        v-if="showAddSupplierModal"
        :visible="showAddSupplierModal"
        @on-close="showAddSupplierModal = false"
        @on-added-supplier="onAddedSupplier"
      />
    </div>
  </PageLayout>
</template>
<script>
import { ref, computed, getCurrentInstance } from 'vue';

import { useTenancy, useUserPermissions } from '@/modules/auth';
import { Button, PageLayout } from '@/modules/core';
import { PlusIcon } from '@/assets/icons';
import { useFetchAll } from '@/modules/purchase-management/compositions/useFetchAll';
import { USER_NEW_PERMISSIONS_LIST } from '@/permissions';

import SuppliersTable from './components/SuppliersTable.vue';
import AddSupplierModal from './components/AddSupplierModal.vue';
import { useAgreements } from './compositions/useAgreements';
import { useOrderTermsForSuppliers } from './compositions/useOrderTermsForSuppliers';
import { useRecommendedPurchaseListItems } from './compositions/useRecommendedPurchaseListItems';

const ALL_RESULTS_LIMIT = 100;
export default {
  name: 'SuppliersSettingsPage',
  components: { Button, PlusIcon, PageLayout, SuppliersTable, AddSupplierModal },
  props: {},
  emits: [''],
  setup() {
    const root = getCurrentInstance().proxy;
    const showAddSupplierModal = ref(false);
    const supplierSearch = ref();

    const { currentTenant } = useTenancy();

    const { isUserPermittedForActiveTenant } = useUserPermissions();
    const hasPermissionForEditingAgreements = isUserPermittedForActiveTenant(USER_NEW_PERMISSIONS_LIST.EDIT_AGREEMENTS);

    const { allResults: purchaseListItems, loading: purchaseListItemsLoading } = useFetchAll({
      hook: useRecommendedPurchaseListItems,
      variables: {
        currentTenant,
      },
      dataSelector: 'purchaseListItems',
      limit: ALL_RESULTS_LIMIT,
    });

    const { allResults: orderTerms, loading: orderTermsLoading } = useFetchAll({
      hook: useOrderTermsForSuppliers,
      variables: {
        currentTenant,
      },
      dataSelector: 'orderTerms',
      limit: ALL_RESULTS_LIMIT,
    });

    const { allResults: agreements, loading: agreementsLoading } = useFetchAll({
      hook: useAgreements,
      variables: {
        currentTenant,
      },
      dataSelector: 'agreementsNew',
      limit: ALL_RESULTS_LIMIT,
    });

    const loading = computed(
      () => purchaseListItemsLoading.value || orderTermsLoading.value || agreementsLoading.value
    );

    const totalCount = computed(() => agreements.value.length);

    const onAddedSupplier = ({ supplierId }) => {
      showAddSupplierModal.value = false;
      root.$router.push({
        name: 'supplierSettings',
        params: { supplierId },
      });
    };

    return {
      loading,
      totalCount,
      agreements,
      orderTerms,
      supplierSearch,
      purchaseListItems,
      showAddSupplierModal,
      hasPermissionForEditingAgreements,
      onAddedSupplier,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.suppliers {
  color: $typography-primary;
  font-weight: 700;
}
.search-supplier {
  height: 2rem;
  width: 14rem;
}
</style>
