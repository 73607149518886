import { isNil } from 'ramda';
import { mixins, generateChart } from 'vue-chartjs';

import { useCurrency } from '@/locale/useCurrency';

import typographyStyles from '@/stylesheets/scss/typography.module.scss';

import { formatCurrencyOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';

const { reactiveProp } = mixins;

const RoundedBar = generateChart('rounded-bar', 'roundedBar');

export default {
  extends: RoundedBar,
  mixins: [reactiveProp],
  props: {
    min: { type: Date },
    max: { type: Date },
  },
  setup() {
    const { currencyFormat } = useCurrency();
    return {
      currencyFormat,
    };
  },
  computed: {
    options() {
      return {
        devicePixelRatio: 2,
        maintainAspectRatio: false,
        responsive: true,
        barRoundness: 1,
        hover: {
          mode: 'x',
          intersect: false,
        },
        legend: {
          display: false,
        },
        tooltips: {
          display: true,
          displayColors: false,
          bodyAlign: this.$direction === 'rtl' ? 'right' : 'left',
          rtl: this.$direction === 'rtl',
          callbacks: {
            title: () => {},
            label: (tooltipItem, data) => {
              const price = this.$i18n.t('terms.supplierTerms.purchaseHistoryTable.amount');
              const quantityString = this.$i18n.t('commons.quantity');
              const quantity = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].metadata?.quantity;

              return [
                `${price}: ${formatCurrencyOrDash(tooltipItem.yLabel, this.currencyFormat)}`,
                `${!isNil(quantity) ? `${quantityString}: ${Number(quantity).toLocaleString()}` : ''}`,
              ];
            },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                fontColor: typographyStyles.ticksFontColor,
                beginAtZero: true,
                maxTicksLimit: 4,
                callback: (value) => {
                  return this.formatMoney(value);
                },
              },
              gridLines: {
                display: true,
                drawBorder: false,
              },
            },
          ],
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              time: {
                unit: 'month',
                round: true,
                displayFormats: {
                  month: 'MM.YY',
                },
              },
              offset: true,
              ticks: {
                min: this.min,
                max: this.max,
                fontColor: this.ticksFontColor,
                fontFamily: this.ticksFontFamily,
                maxRotation: 0,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      };
    },
  },
  methods: {
    formatMoney(value) {
      if (value > 1000) {
        const valueDivided = value / 1000;

        const formatUnits = [
          formatCurrencyOrDash(valueDivided, { ...this.currencyFormat, minimumFractionDigits: 0 }),
          'k',
        ];

        if (this.$direction === 'rtl') formatUnits.reverse();
        return formatUnits.join('');
      } else return formatCurrencyOrDash(value, { ...this.currencyFormat, minimumFractionDigits: 0 });
    },
  },
  watch: {
    options(options) {
      this.renderChart(this.chartData, options);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
